// Accepts a standard promise and a wait timer (in ms).
// Promise will not resolve/reject until the wait timer has finished.

function DelayedPromise(promise, wait = 0) {
	return new Promise((resolve, reject) => {
		promise.then(res => {
			setTimeout(() => resolve(res), wait);
		}).catch(err => {
			setTimeout(() => reject(err), wait)
		})
	});
}

export default DelayedPromise;