const defaultRoute = {
	exact: false,
	render: null,
	path: null,
	private: false,
	useLayout: true,
	title: null,
};





export default function createRoute(routeConfig = {}) {
	let fullRoute = {...defaultRoute, ...routeConfig};
	let {exact, path, render, ...extras} = fullRoute;

	return {route: {exact, path}, extras, render};
}