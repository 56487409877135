import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Divider, Row, Spin, Typography } from 'antd';
import { BarcodeOutlined, FolderOutlined, MailOutlined, SendOutlined, TagOutlined } from "@ant-design/icons";
import { actions } from "../../base/store";
import OrderViewInvoiceButton from "./OrderViewInvoiceButton";



const OrderDetailRow = ({ record }) => {
	const dispatch = useDispatch();

	const shippingData = useSelector(state => state.orders.shippingData[ record.key ]),
		  invoices  = useSelector(state => state.orders.orderInvoices[ record.ges_number ]);

	const showSpinner = !Boolean(shippingData);

	const getOrderInvoices = useCallback(() => {
		dispatch(actions.orders.getOrderInvoices(record.ges_number));
	}, [ dispatch, record.ges_number ]);

	const getShippingData = useCallback(() => {
		dispatch(actions.orders.getOrderShippingInfo(record.key, record.ges_number, record.model_number));
	}, [ dispatch, record.key, record.ges_number, record.model_number ]);


	useEffect(() => {
		if(!invoices) {
			getOrderInvoices();
		}
	}, [ invoices, getOrderInvoices ]);


	useEffect(() => {
		if(!shippingData) {
			getShippingData();
		}
	}, [ shippingData, getShippingData ]);

	return (
		<div>
			<Row gutter={[ 16, 16 ]}>
				<Col xs={24} md={12}>
					<Card className="ts-card-dark-border">
						<Typography.Title level={4}><BarcodeOutlined /> Global Order No.</Typography.Title>
						<Typography.Text strong>{record.ges_number}</Typography.Text>

						<Divider />

						<Typography.Title level={4}><MailOutlined /> Sales Order No.</Typography.Title>
						<Typography.Text strong>{record.order_number}</Typography.Text>

						<Divider />
						{invoices !== undefined &&
						<>
							{invoices.map((invoice, index) => (
								<div key={invoice}>
									<OrderViewInvoiceButton
										docEntry={invoice.invoice_key}
										docNum={invoice.invoice_number}
									/>
								</div>
							))}
						</>
						}
					</Card>
				</Col>


				<Col xs={24} md={12}>
					<Card className="ts-card-dark-border">
						<Typography.Title level={4}><SendOutlined /> Shipping &amp; Billing</Typography.Title>

						<div>
							<Typography.Text strong>Shipping Address</Typography.Text>
						</div>
						<div>
							<Typography.Text type="secondary" className="ts-pre-wrap">
								{showSpinner ? <Spin /> : shippingData.shipping_address}
							</Typography.Text>
						</div>
						<div style={{ marginTop: 16 }}>
							<Typography.Text strong>Billing Address</Typography.Text>
						</div>
						<div>
							<Typography.Text type="secondary" className="ts-pre-wrap">
								{showSpinner ? <Spin /> : shippingData.billing_address}
							</Typography.Text>
						</div>
					</Card>
				</Col>


				<Col xs={24} md={12}>
					<Card className="ts-card-dark-border">
						<Typography.Title level={4}><TagOutlined /> Order Info</Typography.Title>

						<Row gutter={[ 32, 12 ]}>
							<Col xs={4} md={6}>
								<Typography.Text>Order Date</Typography.Text>
							</Col>
							<Col xs={20} md={18}>
								<Typography.Text type="secondary">{record.order_date}</Typography.Text>
							</Col>

							<Col xs={4} md={6}>
								<Typography.Text>Status</Typography.Text>
							</Col>
							<Col xs={20} md={18}>
								<Typography.Text type="secondary">{record.order_status}</Typography.Text>
							</Col>

							<Col xs={4} md={6}>
								<Typography.Text>Sales Rep</Typography.Text>
							</Col>
							<Col xs={20} md={18}>
								<Typography.Text type="secondary">{record.sales_name}</Typography.Text>
							</Col>

							<Col xs={4} md={6}>
								<Typography.Text>Shipped Via</Typography.Text>
							</Col>
							<Col xs={20} md={18}>
								<Typography.Text type="secondary">
									{showSpinner ? <Spin /> : shippingData.shipping_method}
								</Typography.Text>
							</Col>

							<Col xs={4} md={6}>
								<Typography.Text>Date Shipped</Typography.Text>
							</Col>
							<Col xs={20} md={18}>
								<Typography.Text type="secondary">
									{showSpinner ? <Spin /> : shippingData.ship_date}
								</Typography.Text>
							</Col>

							<Col xs={4} md={6}>
								<Typography.Text>Tracking No.</Typography.Text>
							</Col>
							<Col xs={20} md={18}>
								<Typography.Text type="secondary">{showSpinner ?
																   <Spin /> : shippingData.tracking_number}</Typography.Text>
							</Col>

							<Col xs={4} md={6}>
								<Typography.Text>Purchase Order No.</Typography.Text>
							</Col>
							<Col xs={20} md={18}>
								<Typography.Text type="secondary">{record.po_number}</Typography.Text>
							</Col>

							<Col xs={4} md={6}>
								<Typography.Text>Reference No.</Typography.Text>
							</Col>
							<Col xs={20} md={18}>
								<Typography.Text type="secondary">{record.reference_number}</Typography.Text>
							</Col>

							<Col xs={4} md={6}>
								<Typography.Text>Tag No.</Typography.Text>
							</Col>
							<Col xs={20} md={18}>
								<Typography.Text type="secondary">{record.tag_number}</Typography.Text>
							</Col>
						</Row>
					</Card>
				</Col>


				<Col xs={24} md={12}>
					<Card className="ts-card-dark-border">
						<Typography.Title level={4}><FolderOutlined /> Item Info</Typography.Title>

						<Row gutter={[ 32, 12 ]}>
							<Col xs={4} md={6}>
								<Typography.Text>Manufacturer</Typography.Text>
							</Col>
							<Col xs={20} md={18}>
								<Typography.Text type="secondary">{record.manufacturer}</Typography.Text>
							</Col>

							<Col xs={4} md={6}>
								<Typography.Text>Model No.</Typography.Text>
							</Col>
							<Col xs={20} md={18}>
								<Typography.Text type="secondary">{record.model_number}</Typography.Text>
							</Col>

							<Col xs={4} md={6}>
								<Typography.Text>Serial No.</Typography.Text>
							</Col>
							<Col xs={20} md={18}>
								<Typography.Text type="secondary">{record.serial_number}</Typography.Text>
							</Col>

							<Col xs={4} md={6}>
								<Typography.Text>Description</Typography.Text>
							</Col>
							<Col xs={20} md={18}>
								<Typography.Text type="secondary">{record.description}</Typography.Text>
							</Col>

							<Col xs={4} md={6}>
								<Typography.Text>Price</Typography.Text>
							</Col>
							<Col xs={20} md={18}>
								<Typography.Text type="secondary">{record.price}</Typography.Text>
							</Col>
						</Row>
					</Card>
				</Col>

			</Row>
		</div>
	)
};


export default OrderDetailRow;