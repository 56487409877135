import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from "base/store";
import { Button, Modal, Spin } from "antd";
import savePDF from "lib/saveb64PDF";



const InvoicePDFModal = () => {
	const dispatch = useDispatch();

	const loading = useSelector(state => state.invoices.PDFmodalLoading),
		  visible = useSelector(state => state.invoices.showPDFModal),
		  docNum  = useSelector(state => state.invoices.PDFmodalDocNum),
		  pdf     = useSelector(state => state.invoices.invoicePDFs[ state.invoices.PDFmodalDocEntry ]);


	const resetModal = useCallback(() => {
		dispatch(actions.invoices.resetPDFModal());
	}, [ dispatch ]);



	return (
		<Modal
			title={`Invoice ${docNum}`}
			className="ts-pdf-modal"
			visible={visible}
			centered
			closable={false}
			footer={[
				pdf ?
				<Button
					key="pdfnewtab"
					onClick={() => {
						savePDF(pdf, `GES Invoice ${docNum}`)
					}}
					type="primary"
				>Download PDF</Button>
					: null,
				<Button key="close" onClick={resetModal} disabled={loading}>
					Close
				</Button>,
			]}
		>

			{pdf ? <embed src={`data:application/pdf;base64,${pdf}`} className="ts-pdf" /> : <Spin />}

		</Modal>

	)
};


export default InvoicePDFModal;