import React from 'react';
import Router from './Router';
import { Provider } from 'react-redux';
import store from './store';
import './overrides.less';
import './App.sass';
import 'react-credit-cards/lib/styles.scss';


const App = () => (
	<Provider store={store}>
		<Router />
	</Provider>
);


export default App;
