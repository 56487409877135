import React, { useCallback, useEffect } from 'react';
import {useParams} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../base/store";
import {columns} from './Invoices.tableConfig';
import {Button, Table, Typography} from 'antd';
import InvoicePDFModal from "./InvoicePDFModal";
import InvoicePaymentModal from "./InvoicePaymentModal";
import {CreditCardOutlined, ReloadOutlined} from "@ant-design/icons";



function getMode(mode) {
	switch((mode || '').toLowerCase()) {
		case 'paid':
			return 'paid';
		case 'unpaid':
			return 'unpaid';
		default:
			return 'all';
	}
}
const Invoices = () => {
	let mode = getMode(useParams().mode);
	const dispatch = useDispatch();

	const invoices = useSelector(state => state.invoices.invoices),
		  loading  = useSelector(state => state.invoices.loading),
		  selectedRows  = useSelector(state => state.invoices.selectedRows);


	const refreshInvoices = useCallback((mode) => {
		dispatch(actions.invoices.refreshInvoices(mode));
	}, [ dispatch ]);

	const resetInvoices = useCallback(() => {
		dispatch(actions.invoices.resetInvoices());
	}, [ dispatch ]);

	const handlePayInvoices =  () => {
		dispatch(actions.invoices.openPaymentModal());
	};

	useEffect(() => {
		refreshInvoices(mode);
		return () => resetInvoices();
	}, [ refreshInvoices, resetInvoices, mode ]);



	const rowSelection = {
		hideDefaultSelections: true,
		selectedRowKeys: selectedRows,
		onSelectAll: (selected, selectedRows, changeRows) => {
			dispatch(actions.invoices.toggleSelectAll());
		},
		onSelect: (record, selected, selectedRows, nativeEvent) => {
			dispatch(actions.invoices.toggleSingleSelection(selected, record));
		},
	};

	return (
		<div className="ts-home-content-container ts-portal-card">
			<Typography.Title level={2}>{mode.charAt(0).toUpperCase() + mode.slice(1)} Invoices</Typography.Title>
			<Button
				type="primary"
				disabled={selectedRows.length === 0}
				icon={<CreditCardOutlined />}
				onClick={handlePayInvoices}
				className="spacer_right"
			>
				Pay Selected Invoices
			</Button>

			<Button
				disabled={loading}
				loading={loading}
				icon={<ReloadOutlined />}
				onClick={() => refreshInvoices(mode)}
			>
				Refresh Invoices
			</Button>
			<Table
				columns={columns}
				dataSource={invoices}
				loading={loading}
				pagination={{
					position: ['bottomRight', 'topRight'],
					showSizeChanger: true,
					defaultPageSize: 10,
					pageSizeOptions: ['5', '10', '20', '50', '100'],
				}}
				rowSelection={mode !== 'paid' ? rowSelection : undefined}
			/>

			<InvoicePDFModal />
			<InvoicePaymentModal />
		</div>
	);
};


export default Invoices;