import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from "base/store";
import { Button, Form, Input, message, Modal, notification, Select, Typography, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import fileToBase64 from "lib/fileToBase64";



const emailValidator = {
	validator: (rule, value) => {
		if(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
			return Promise.resolve();
		}

		return Promise.reject('Please enter a valid email address');
	},
	validateTrigger: 'onSubmit',
};



const OrderApprovalModal = () => {
	const dispatch                        = useDispatch(),
		  [ form ]                        = Form.useForm(),
		  [ files, setFiles ]             = useState([]),
		  [ showCollect, setShowCollect ] = useState(false),
		  allowedTypes                    = [ 'doc', 'docx', 'xls', 'xlsx', 'txt', 'odt', 'pdf' ];



	const pending   = useSelector(state => state.orders.approvalPending),
		  visible   = useSelector(state => state.orders.showApprovalModal),
		  docNum    = useSelector(state => state.orders.approvalModalDocNum),
		  docEntry  = useSelector(state => state.orders.approvalModalDocEntry),
		  gesNum    = useSelector(state => state.orders.approvalModalGESNumber),
		  completed = useSelector(state => state.orders.approvalCompleted),
		  succeeded = useSelector(state => state.orders.approvalSucceeded);



	const resetModal = useCallback(() => {
		setFiles([]);
		dispatch(actions.orders.resetApprovalModal());
	}, [ dispatch, setFiles ]);


	const refreshOrders = useCallback((silent = true) => {
		dispatch(actions.orders.refreshOrders(silent));
	}, [ dispatch ]);


	const handleApprove = useCallback(async (values) => {
		let fileData = null;
		let fileName = null;

		if(files.length) {
			try {
				fileName = files[ 0 ].name;
				fileData = await fileToBase64(files[ 0 ]);
				fileData = fileData.split(';');
				fileData.shift();
				fileData = fileData.join('');
				fileData = fileData.replace(/^base64,/, "");
			} catch(err) {
				console.error(err);
				message.error('Error uploading file!');
				return false;
			}
		}

		dispatch(actions.orders.approve({
			order_key: docEntry,
			order_number: docNum,
			ges_number: gesNum,
			first_name: values.first_name,
			last_name: values.last_name,
			company_name: values.company_name,
			phone: values.phone,
			email: values.email,
			po_number: values.po_number,
			ref_number: values.ref_number,
			shipping_method: values.shipping_method,
			notes: values.notes,
			collect_account: values.collect_account || '',
			attachment: fileData,
			attachment_name: fileName,
		}));
	}, [ dispatch, files, docEntry, docNum, gesNum ]);


	const handleShippingChange = value => {
		setShowCollect(value.includes('COLLECT'));
	};


	const handleFileUpload = fileData => {
		if(fileData.size / 1024 / 1024 > 5) {
			message.error('File cannot be larger than 5 MB', 8);
			return false;
		}

		let fileParts = fileData.name.split('.');

		if(fileParts.length < 2 || !allowedTypes.includes(fileParts[ fileParts.length - 1 ].toLowerCase())) {
			message.error(`File must be one of the following types: ${allowedTypes.join(', ')}`, 8);
			return false;
		}


		setFiles([ fileData ]);
		return false;
	};




	useEffect(() => {
		if(!visible) {
			form.resetFields();
		}
	}, [ visible, form ]);


	useEffect(() => {
		if(completed) {

			if(succeeded) {
				notification.success({
					message: 'Approval Success!',
					description: 'Your order was approved successfully.',
					duration: 8,
				});

				resetModal();
				refreshOrders();
			} else {

			}

		}
	}, [ completed, succeeded, resetModal, refreshOrders ]);




	return (
		<Modal
			title={`Order ${docNum} Approval (GES No. ${gesNum})`}
			visible={visible}
			forceRender
			maskClosable={false}
			centered
			onCancel={resetModal}
			footer={[
				<Button key="cancel" onClick={resetModal} disabled={pending}>
					Cancel
				</Button>,
				<Button key="submit" type="primary" onClick={form.submit} loading={pending}>
					Approve
				</Button>,
			]}
		>
			<Form
				name="order_approval"
				layout="horizontal"
				labelCol={{ xs: { span: 24 }, lg: { span: 8 } }}
				wrapperCol={{ xs: { span: 24 }, lg: { span: 16 } }}
				labelAlign="left"
				form={form}
				onFinish={handleApprove}
			>
				<Form.Item
					name="first_name"
					label="First Name"
					rules={[ { required: true, message: 'First Name is required' } ]}
				>
					<Input maxLength={80} />
				</Form.Item>

				<Form.Item
					name="last_name"
					label="Last Name"
					rules={[
						{ required: true, message: 'Last Name is required' },
					]}
				>
					<Input maxLength={80} />
				</Form.Item>

				<Form.Item
					name="company_name"
					label="Company"
					rules={[
						{ required: true, message: 'Company is required' },
					]}
				>
					<Input maxLength={100} />
				</Form.Item>

				<Form.Item
					name="phone"
					label="Phone Number"
					rules={[
						{ required: true, message: 'Phone Number is required' },
					]}
				>
					<Input maxLength={30} />
				</Form.Item>

				<Form.Item
					name="email"
					label="Email"
					rules={[
						{ required: true, message: 'Email is required' },
						() => (emailValidator),
					]}
				>
					<Input maxLength={100} />
				</Form.Item>

				<Form.Item
					name="po_number"
					label="PO Number"
					rules={[
						{ required: true, message: 'Purchase Order Number is required' },
					]}
				>
					<Input maxLength={100} />
				</Form.Item>


				<Form.Item
					name="ref_number"
					label="Reference Number"
				>
					<Input maxLength={100} />
				</Form.Item>

				<Form.Item
					name="shipping_method"
					label="Shipping Method"
					rules={[ { required: true, message: 'Shipping Method is required' } ]}
				>
					<Select placeholder="Select shipping method" onChange={handleShippingChange}>
						<Select.Option value="COLLECT - FEDEX INTERNATIONAL">COLLECT - FEDEX
							INTERNATIONAL</Select.Option>
						<Select.Option value="COLLECT - Fedex Priority Overnight">COLLECT - Fedex Priority
							Overnight</Select.Option>
						<Select.Option value="COLLECT - FREIGHT">COLLECT - FREIGHT</Select.Option>
						<Select.Option value="COLLECT - UPS 2nd Day Air">COLLECT - UPS 2nd Day Air</Select.Option>
						<Select.Option value="COLLECT - UPS 2nd Day Air AM">COLLECT - UPS 2nd Day Air AM</Select.Option>
						<Select.Option value="COLLECT - UPS 3 Day Select">COLLECT - UPS 3 Day Select</Select.Option>
						<Select.Option value="COLLECT - UPS Ground">COLLECT - UPS Ground</Select.Option>
						<Select.Option value="COLLECT - UPS Next Day Air">COLLECT - UPS Next Day Air</Select.Option>
						<Select.Option value="COLLECT - UPS Next Day Air Early AM">COLLECT - UPS Next Day Air Early
							AM</Select.Option>
						<Select.Option value="COLLECT - UPS Next Day Air Sat Del">COLLECT - UPS Next Day Air Sat
							Del</Select.Option>
						<Select.Option value="COLLECT - UPS Next Day Air Saver">COLLECT - UPS Next Day Air
							Saver</Select.Option>
						<Select.Option value="COLLECT - UPS Standard">COLLECT - UPS Standard</Select.Option>
						<Select.Option value="COLLECT - UPS Worldwide Express Saver">COLLECT - UPS Worldwide Express
							Saver</Select.Option>
						<Select.Option value="COURIER">COURIER</Select.Option>
						<Select.Option value="DHL">DHL</Select.Option>
						<Select.Option value="FEDEX GROUND">FEDEX GROUND</Select.Option>
						<Select.Option value="FEDEX PRIORITY OVERNIGHT">FEDEX PRIORITY OVERNIGHT</Select.Option>
						<Select.Option value="FREIGHT">FREIGHT</Select.Option>
						<Select.Option value="FREIGHT - AAA COOPER">FREIGHT - AAA COOPER</Select.Option>
						<Select.Option value="FREIGHT - CON-WAY">FREIGHT - CON-WAY</Select.Option>
						<Select.Option value="FREIGHT - ESTES">FREIGHT - ESTES</Select.Option>
						<Select.Option value="FREIGHT - FEDEX FREIGHT">FREIGHT - FEDEX FREIGHT</Select.Option>
						<Select.Option value="FREIGHT - OLD DOMINION">FREIGHT - OLD DOMINION</Select.Option>
						<Select.Option value="FREIGHT - R+L">FREIGHT - R+L</Select.Option>
						<Select.Option value="FREIGHT - SAIA">FREIGHT - SAIA</Select.Option>
						<Select.Option value="FREIGHT - SOUTHEASTERN">FREIGHT - SOUTHEASTERN</Select.Option>
						<Select.Option value="FREIGHT - UPS FREIGHT">FREIGHT - UPS FREIGHT</Select.Option>
						<Select.Option value="FREIGHT - YELLOW">FREIGHT - YELLOW</Select.Option>
						<Select.Option value="PICKUP">PICKUP</Select.Option>
						<Select.Option value="TNT EXPRESS">TNT EXPRESS</Select.Option>
						<Select.Option value="UPS 2nd Day Air">UPS 2nd Day Air</Select.Option>
						<Select.Option value="UPS 3 Day Select">UPS 3 Day Select</Select.Option>
						<Select.Option value="UPS Ground">UPS Ground</Select.Option>
						<Select.Option value="UPS Next Day Air">UPS Next Day Air</Select.Option>
						<Select.Option value="UPS Next Day Air Early AM">UPS Next Day Air Early AM</Select.Option>
						<Select.Option value="UPS Next Day Air Sat Del">UPS Next Day Air Sat Del</Select.Option>
						<Select.Option value="UPS Next Day Air Saver">UPS Next Day Air Saver</Select.Option>
						<Select.Option value="UPS Standard">UPS Standard</Select.Option>
						<Select.Option value="COLLECT - Fedex 2nd Day Air">COLLECT - Fedex 2nd Day Air</Select.Option>
						<Select.Option value="COLLECT - DHL">COLLECT - DHL</Select.Option>
						<Select.Option value="COLLECT - Fedex Express Saver (3-Day)">COLLECT - Fedex Express Saver
							(3-Day)</Select.Option>
						<Select.Option value="COLLECT - Fedex Ground">COLLECT - Fedex Ground</Select.Option>
					</Select>
				</Form.Item>

				{showCollect &&
				<Form.Item
					name="collect_account"
					label="Collect Account No."
					rules={[ { required: true, message: 'Collect Account Number is required' } ]}
				>
					<Input maxLength={100} />
				</Form.Item>
				}

				<Form.Item
					name="notes"
					label="Notes"
				>
					<Input.TextArea />
				</Form.Item>


				<Form.Item
					name="attachment"
					label="Attach Document"
				>
					<Upload
						beforeUpload={handleFileUpload}
						onRemove={() => (setFiles([]))}
						fileList={files}
					>
						<div><Button icon={<UploadOutlined />}>Click to Upload</Button></div>
						<Typography.Text>Allowed file types are {allowedTypes.join(', ')}</Typography.Text>

					</Upload>
				</Form.Item>
			</Form>
		</Modal>

	);
};


export default OrderApprovalModal;