import React from 'react';
import createRoute from './Router/createRoute';
import Home from 'pages/Home';
import Invoices from 'pages/Invoices';
import Orders from 'pages/Orders';
import Addresses from 'pages/Addresses';
import Logout from 'pages/Logout';
import RMA from 'pages/RMA';
import Contact from 'pages/Contact';



export const routes = [

	createRoute({
		path: '/logout',
		exact: true,
		render: <Logout />,
		title: 'Log Out',
	}),

	createRoute({
		path: '/invoices/:mode?',
		exact: true,
		render: <Invoices />,
		title: 'Invoices',
	}),

	createRoute({
		path: '/orders/:mode?',
		exact: true,
		render: <Orders />,
		title: 'Invoices',
	}),

	createRoute({
		path: '/account/addresses',
		exact: true,
		render: <Addresses />,
		title: 'Address Manager',
	}),

	createRoute({
		path: '/rma',
		exact: true,
		render: <RMA />,
		title: 'New RMA',
	}),

	createRoute({
		path: '/contact',
		exact: true,
		render: <Contact />,
		title: 'Contact Global',
	}),

	createRoute({
		path: '/',
		exact: true,
		render: <Home />,
		title: 'Home',
	}),

];