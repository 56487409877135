export default function filterArrayOfObjects(arr, searchString) {
	return arr.filter(row => {
		let keys = Object.keys(row);


		for(let i = 0; i < keys.length; i++) {
			let value = row[keys[i]];


			if(typeof value !== 'string') {
				value = String(value);
			}

			if(value.toUpperCase().indexOf(searchString.toUpperCase()) !== -1) {
				return true;
			}
		}


		return false;
	});
}