import axios from 'axios';
import DelayedPromise from "lib/DelayedPromise";
import { message } from "antd";
import fixNewlines from "lib/fixNewlines";



const initialState = {
	loading: false,
	orders: [],
	ordersAll: [],
	shippingData: {},
	orderInvoices: {},
	orderPDFs: {},
	orderStatuses: [],
	filtered: {},
	showApprovalModal: false,
	approvalModalDocNum: null,
	approvalModalDocEntry: null,
	approvalModalGESNumber: null,
	approvalModalRowID: null,
	approvalPending: false,
	approvalCompleted: false,
	approvalSucceeded: false,
	invoiceModalLoading: false,
	invoiceModalDocEntry: null,
	invoiceModalDocNum: null,
	showInvoiceModal: false,
};


export const types = {
	SET_STATE: 'ORDERS/SET_STATE',
};




export default (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };
		default:
			return state;
	}
}



export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),


	resetOrders: () => ({ type: types.SET_STATE, data: { ...initialState } }),


	resetApprovalModal: () => ({
		type: types.SET_STATE, data: {
			showApprovalModal: false,
			approvalModalDocNum: null,
			approvalModalDocEntry: null,
			approvalModalGESNumber: null,
			approvalModalRowID: null,
			approvalPending: false,
			approvalCompleted: false,
			approvalSucceeded: false,
		},
	}),


	resetInvoiceModal: () => ({
		type: types.SET_STATE,
		data: {
			showInvoiceModal: false,
			invoiceModalDocNum: null,
			invoiceModalDocEntry: null,
			invoiceModalLoading: false,
		},
	}),


	refreshOrders: (silent = false) => async (dispatch) => {
		dispatch({ type: types.SET_STATE, data: { loading: true } });

		try {
			let invoiceRequest = await DelayedPromise(
				axios.get(`${process.env.REACT_APP_API_URL}/orders?silent=${silent.toString()}`),
				1000,
			);

			dispatch({
				type: types.SET_STATE, data: {
					loading: false,
					orders: invoiceRequest.data.orders,
					ordersAll: invoiceRequest.data.orders,
					orderStatuses: [ ...new Set(invoiceRequest.data.orders
						.map(row => row.order_status).sort()),
					].map(row => ({ text: row, value: row })),
				},
			});
		} catch(err) {
			dispatch({ type: types.SET_STATE, data: { loading: false } });
		}
	},


	getOrderInvoices: (gesNumber) => async (dispatch, getState) => {
		try {
			let invoicesRequest = await axios.get(`${process.env.REACT_APP_API_URL}/orders/invoice_keys/${gesNumber}`);


			dispatch({
				type: types.SET_STATE, data: {
					loading: false,
					orderInvoices: {
						...getState().orders.orderInvoices,
						[ gesNumber ]: invoicesRequest.data.invoice_keys,
					},
				},
			});
		} catch(err) {

		}
	},



	getOrderShippingInfo: (key, gesNumber, itemCode) => async (dispatch, getState) => {
		try {
			let shippingRequest = await axios.get(`${process.env.REACT_APP_API_URL}/orders/shipping?gesNum=${gesNumber}&item=${itemCode}`);

			let data = shippingRequest.data.shipping_data;
			data.shipping_address = fixNewlines(data.shipping_address);
			data.billing_address = fixNewlines(data.billing_address);

			dispatch({
				type: types.SET_STATE, data: {
					loading: false,
					shippingData: {
						...getState().orders.shippingData,
						[ key ]: data,
					},
				},
			});
		} catch(err) {

		}
	},


	viewPDF: (docEntry, docNum, silent = false) => async (dispatch, getState) => {
		let hasPDF = Boolean(getState().orders.orderPDFs[ docEntry ]);

		dispatch({
			type: types.SET_STATE, data: {
				invoiceModalLoading: !hasPDF,
				invoiceModalDocEntry: docEntry,
				invoiceModalDocNum: docNum,
				showInvoiceModal: true,
			},
		});

		if(hasPDF) {
			return true;
		}

		try {
			let pdfRequest = await axios.get(`${process.env.REACT_APP_API_URL}/orders/invoice/${docEntry}?silent=${silent.toString()}`, { timeout: 60000 });

			dispatch({
				type: types.SET_STATE, data: {
					invoiceModalLoading: false,
					orderPDFs: { ...getState().orders.orderPDFs, [ docEntry ]: pdfRequest.data.fileData },
				},
			});
		} catch(err) {
			if(err.message && err.message.indexOf('timeout') !== -1) {
				message.error('PDF fetching timed out after 1 minute. Please try again later.');
			}
			dispatch(actions.resetInvoiceModal());
		}
	},


	approve: (data) => async (dispatch, getState) => {
		dispatch({
			type: types.SET_STATE, data: {
				approvalPending: true,
				approvalCompleted: false,
			},
		});


		try {
			await axios.post(`${process.env.REACT_APP_API_URL}/orders/approve`, data, { timeout: 60000 });

			dispatch({
				type: types.SET_STATE, data: {
					approvalPending: false,
					approvalCompleted: true,
					approvalSucceeded: true,
				},
			});
		} catch(err) {
			if(err.message && err.message.indexOf('timeout') !== -1) {
				message.error('Approval request timed out after 1 minute. Please try again later.');
				dispatch(actions.resetInvoiceModal());
			}

			dispatch({
				type: types.SET_STATE, data: {
					approvalPending: false,
					approvalCompleted: true,
					approvalSucceeded: false,
				},
			});
		}
	},


};