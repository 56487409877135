import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { actions } from "base/store";
import { Col, Row, Table, Tag, Typography } from 'antd';
import OrderDetailRow from "./OrderDetailRow";
import OrderApprovalModal from "./OrderApprovalModal";
import OrderPDFModal from "./OrderPDFModal";
import OrderApproveButton from "./OrderApproveButton";
import OrderSearchInput from "./OrderSearchInput";
import makeAntColumns from "lib/makeAntColumns";

function getMode(mode) {
	switch((mode || '').toLowerCase()) {
		case 'awaiting_approval':
			return 'awaiting_approval';
		case 'repaired':
			return 'repaired';
		case 'processing':
			return 'processing';
		default:
			return 'all';
	}
}

const Orders = () => {
	let mode = getMode(useParams().mode);
	const dispatch = useDispatch();


	const orders   = useSelector(state => state.orders.orders),
		  loading  = useSelector(state => state.orders.loading),
		  statuses = useSelector(state => state.orders.orderStatuses),
		  filtered = useSelector(state => state.orders.filtered);


	const refreshOrders = useCallback(() => {
		dispatch(actions.orders.refreshOrders());
	}, [ dispatch ]);

	const resetOrders = useCallback(() => {
		dispatch(actions.orders.resetOrders());
	}, [ dispatch ]);

	const handleTableChange = useCallback((pagination, filters, sorter) => {
		dispatch(actions.orders.setState({ filtered: filters }));
	}, [ dispatch ]);




	// Automatic filtering based on url
	useEffect(() => {
		switch((mode || '').toLowerCase()) {
			case 'awaiting_approval':
				dispatch(actions.orders.setState({
					filtered: { order_status: [ 'Awaiting Approval', 'Awaiting Approval - Eval Complete' ] },
				}));
				break;
			case 'repaired':
				dispatch(actions.orders.setState({
					filtered: { order_status: [ 'Repaired and Tested' ] },
				}));
				break;
			case 'processing':
				dispatch(actions.orders.setState({
					filtered: { order_status: [ 'In Process' ] },
				}));
				break;
			default:
				dispatch(actions.orders.setState({ filtered: {} }));
				break;
		}
	}, [ dispatch, mode ]);




	useEffect(() => {
		refreshOrders();

		return () => resetOrders();
	}, [ refreshOrders, resetOrders ]);




	const columns = makeAntColumns([
		{
			title: 'Order No.',
			dataIndex: 'order_number',
			defaultSortOrder: 'descend',
			sorter: 'number'
		},
		{
			title: 'GES No.',
			dataIndex: 'ges_number',
			sorter: 'number'
		},
		{
			title: 'Date',
			dataIndex: 'order_date',
			sorter: 'string',
			sortIndex: 'date_sortable',
		},
		{
			title: 'Status',
			dataIndex: 'order_status',
			sorter: 'string',
			filters: statuses,
			filteredValue: filtered.order_status || null,
			render: (value, record) => <Tag color={getColor(value)}>{value}</Tag>,
		},
		{
			title: 'Manufacturer',
			dataIndex: 'manufacturer',
			sorter: 'string',
		},
		{
			title: 'Model No.',
			dataIndex: 'model_number',
			sorter: 'string',
		},
		{
			title: 'Serial No.',
			dataIndex: 'serial_number',
			sorter: 'string',
		},
		{
			title: 'Description',
			dataIndex: 'description',
			sorter: 'string',
		},
		{
			title: 'Actions',
			dataIndex: 'order_key',
			width: 120,
			render: (value, record) => {
				let showApproval = (record.order_status || '').toUpperCase().includes('AWAITING APPROVAL');
				return (
					<div>
						{showApproval &&
						<OrderApproveButton
							docNum={record.order_number}
							docEntry={record.order_key}
							gesNum={record.ges_number}
							rowID={record.rowID}
						/>
						}
					</div>
				)
			},
		},
	]);



	return (
		<div className="ts-home-content-container ts-portal-card">
			<Typography.Title level={2}>Orders</Typography.Title>
			<Row>
				<Col xs={24} md={12} lg={6}>
					<OrderSearchInput />
				</Col>
			</Row>

			<Table
				columns={columns}
				dataSource={orders}
				loading={loading}
				onChange={handleTableChange}
				expandable={{
					expandedRowRender: record => <OrderDetailRow record={record} />,
					rowExpandable: record => record.name !== 'Not Expandable',
				}}
				pagination={{
					position: ['bottomRight', 'topRight'],
					showSizeChanger: true,
					defaultPageSize: 10,
					pageSizeOptions: [ '5', '10', '20', '50', '100' ],
				}}
			/>

			<OrderApprovalModal />
			<OrderPDFModal />
		</div>
	);
};


function getColor(status) {
	switch(status.toUpperCase()) {
		case 'AWAITING APPROVAL':
		case 'AWAITING APPROVAL - EVAL COMPLETE':
			return 'green';
		case 'SCRAPPED IN HOUSE':
			return 'red';
		case 'OPEN':
			return 'blue';
		case 'CLOSED':
			return 'orange';
		case 'SHIPPED':
			return 'purple';
		case 'REPAIRED AND TESTED':
			return 'cyan';
		case 'CANCEL BY TECH':
			return 'orange';
		case 'NO PROBLEM FOUND':
			return 'geekblue';
		case 'RETURNED UNREPAIRED':
			return 'volcano';
		default:
			return undefined;
	}
}


export default Orders;