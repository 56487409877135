import React, { useCallback } from 'react';
import { FilePdfOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { actions } from "../../base/store";



const OrderViewInvoiceButton = ({ docEntry, docNum }) => {
	const dispatch = useDispatch();

	const viewPDF = useCallback(() => {
		dispatch(actions.orders.viewPDF(docEntry, docNum));
	}, [ dispatch, docEntry, docNum ]);


	return (
		<Button
			icon={<FilePdfOutlined />}
			onClick={viewPDF}
		>
			View Invoice {docNum}
		</Button>
	)
};



export default OrderViewInvoiceButton;