import React, { useCallback, useEffect } from 'react';
//import { Link } from 'react-router-dom';
import { Alert, Button, Form, Input, Typography } from 'antd';
import { LockOutlined, LoginOutlined, UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'base/store';
import Cookies from 'js-cookie';
import SignupModal from "./SignupModal";


const emailValidator = {
	validator: (rule, value) => {
		if(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
			return Promise.resolve();
		}

		return Promise.reject('Please enter a valid email address');
	},
	validateTrigger: 'onSubmit',
};



const Login = () => {
	const dispatch = useDispatch();
	const [ form ] = Form.useForm();


	const handleLogin = useCallback((values) => {
		dispatch(actions.auth.handleLogin(values));
	}, [ dispatch ]);

	const handleSignup = useCallback((values) => {
		dispatch(actions.auth.setState({showSignupModal: true}));
	}, [ dispatch ]);

	const handlePasswordChange = useCallback((values) => {
		dispatch(actions.auth.handlePasswordChange(values));
	}, [ dispatch ]);

	const verifyToken = useCallback((token) => {
		dispatch(actions.auth.verifyToken(token));
	}, [ dispatch ]);

	const pending            = useSelector(state => state.auth.pending),
		  showPasswordChange = useSelector(state => state.auth.showPasswordChange),
		  showRelog          = useSelector(state => state.auth.showRelog),
		  verifyingToken     = useSelector(state => state.auth.verifyingToken),
		  errors             = useSelector(state => state.auth.errors);

	const authToken = Cookies.get('authToken');


	useEffect(() => {
		if(authToken && !showPasswordChange && !showRelog) {
			verifyToken(authToken);
		}
	}, [ authToken, verifyToken, showPasswordChange, showRelog ]);

	useEffect(() => {
		if(showRelog) {
			form.resetFields([ 'password' ]);
		}
	}, [ showRelog, form ]);


	if(verifyingToken) {
		return null;
	}


	return (
		<div className="ts-home-content-container login-form">

			<div className="ts-portal-card">
				<Typography.Title level={4}><LoginOutlined /> Log In</Typography.Title>

				{showPasswordChange &&
				<Alert
					className="login-alert"
					message="You must change your password before logging in. Please set your new password below."
				/>
				}

				{showRelog &&
				<Alert
					className="login-alert"
					message="Your password has been successfully changed. Please log in again with your new password."
				/>
				}

				{errors.map(message => (
					<Alert className="login-alert" message={message} key={message} type="error" />))}
				<Form
					name="normal_login"
					className="login-form"
					initialValues={{ remember: true }}
					onFinish={showPasswordChange ? handlePasswordChange : handleLogin}
					form={form}
				>
					<Form.Item
						name="email"
						rules={[
							() => (emailValidator),
						]}
					>
						<Input
							prefix={<UserOutlined className="site-form-item-icon" />}
							placeholder="Email Address"
							disabled={pending || showRelog || showPasswordChange}
							type="email"
						/>
					</Form.Item>


					{!showPasswordChange &&
					<Form.Item
						name="password"
						rules={[
							{ required: true, message: 'Password is required' },
							{
								min: 8,
								message: 'Password must be at least 8 characters',
								validateTrigger: 'onSubmit',
							},
						]}
					>
						<Input.Password
							prefix={<LockOutlined className="site-form-item-icon" />}
							placeholder="Password"
							disabled={pending}
						/>
					</Form.Item>
					}


					{showPasswordChange &&
					<Form.Item
						name="password_reset1"
						rules={[
							{ required: true, message: 'New Password is required' },
							{
								min: 8,
								message: 'Password must be at least 8 characters',
								validateTrigger: 'onSubmit',
							},
						]}
					>
						<Input.Password
							prefix={<LockOutlined className="site-form-item-icon" />}
							type="password"
							placeholder="New Password"
							disabled={pending}
						/>
					</Form.Item>
					}

					{showPasswordChange &&
					<Form.Item
						name="password_reset2"
						rules={[
							{ required: true, message: 'New Password is required' },
							{
								min: 8,
								message: 'Password must be at least 8 characters',
								validateTrigger: 'onSubmit',
							},
						]}
					>
						<Input.Password
							prefix={<LockOutlined className="site-form-item-icon" />}
							type="password"
							placeholder="Re-enter New Password"
							disabled={pending}
						/>
					</Form.Item>
					}


					{/*
						<Form.Item>
							<Link className="login-form-forgot" to="/reset_password">
								Forgot password
							</Link>
						</Form.Item>
					*/}


					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							className="ts-full-width"
							loading={pending}
						>
							{showPasswordChange ? 'Change Password' : 'Log In'}
						</Button>
					</Form.Item>

					<Form.Item>
						<Button
							className="ts-full-width"
							loading={pending}
							onClick={handleSignup}
						>
							Sign Up
						</Button>
					</Form.Item>
				</Form>
			</div>

			<SignupModal />
		</div>

	);
};

export default Login;