import React from 'react';
import { Typography } from "antd";



const InvoiceExternalError = () => (
	<Typography.Text>
		We were unable to contact our payment processing service. Please try again in a few minutes by reloading
		this page. If the issue persists, please contact us for assistance.
	</Typography.Text>
);


export default InvoiceExternalError;