import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, message, Select, Typography } from 'antd';
import { ToolOutlined } from '@ant-design/icons';
import axios from 'axios';
import DelayedPromise from "lib/DelayedPromise";



const RMA = () => {

	const [ checkValue, setCheck ]      = useState(false),
		  [ submitted, setSubmit ]      = useState(false),
		  [ submitting, setSubmitting ] = useState(false);


	const handleCheckboxChange = (e) => {
		setCheck(e.target.checked);
	};

	const handleSubmit = async (values) => {
		let submitValues = { ...values, empty_addresses: checkValue ? 'Y' : 'N' };
		setSubmitting(true);

		try {
			await DelayedPromise(axios.post(`${process.env.REACT_APP_API_URL}/rma`, { rma: submitValues }), 1000);
			setSubmit(true);
		} catch(err) {
			message.error("There was an error submitting your RMA. Please try again later.", 4);
			setSubmitting(false);
		}
	};


	if(submitted) {
		return (
			<div className="ts-rma-content-container">
				<div className="ts-portal-card">
					<Typography.Title level={4}><ToolOutlined /> RMA Request</Typography.Title>
					<Typography.Text>
						Your request has been submitted. An email with your RMA number and packing slip
						will arrive shortly.
					</Typography.Text>
				</div>
			</div>
		);
	}

	return (
		<div className="ts-rma-content-container">
			<div className="ts-portal-card">
				<Typography.Title level={4}><ToolOutlined /> RMA Request</Typography.Title>
				<Typography.Text>Please fill out and submit the form below. After submitting, we will email
					you a packing slip with your RMA number.</Typography.Text>

				<Form
					name="rma_request"
					layout="horizontal"
					labelCol={{ xs: { span: 24 }, lg: { span: 6 } }}
					wrapperCol={{ xs: { span: 24 }, lg: { span: 18 } }}
					labelAlign="left"
					onFinish={handleSubmit}
					className="ts-form-margin"
				>

					<Form.Item
						name="repair_type"
						label="Repair Type"
						rules={[ { required: true, message: 'Repair Type is required' } ]}
					>
						<Select placeholder="Select Repair Type">
							<Select.Option value="NEW REPAIR - REGULAR">
								New Repair - Regular
							</Select.Option>
							<Select.Option value="NEW REPAIR - RUSH">
								New Repair - Rush
							</Select.Option>
							<Select.Option value="WARRANTY REPAIR-REG">
								Warranty Repair - Regular
							</Select.Option>
							<Select.Option value="WARRANTY REPAIR-RUSH">
								Warranty Repair - Rush
							</Select.Option>
						</Select>
					</Form.Item>

					<Form.Item
						name="empty_addresses"
						label="Address"
					>
						<Checkbox checked={checkValue} onChange={handleCheckboxChange}>
							Your RMA will be prepopulated with your current on-file default Shipping and
							Billing addresses. <strong>Check here to leave these addresses blank.</strong>
						</Checkbox>
					</Form.Item>

					<Form.Item
						name="subject"
						label="Subject"
						rules={[
							{ required: true, message: 'Subject is required' },
						]}
					>
						<Input
							maxLength={254}
							placeholder="Please enter your model number(s) and OEM(s) of the unit(s) being sent in (max 254 characters)"
						/>
					</Form.Item>


					<Form.Item
						name="description"
						label="Description"
						rules={[
							{ required: true, message: 'Description is required' },
						]}
					>
						<Input.TextArea
							autoSize={{ minRows: 8, maxRows: 8 }}
							maxLength={10000}
						/>
					</Form.Item>

					<Form.Item>
						<Button type="primary" htmlType="submit" loading={submitting}>
							Submit RMA Request
						</Button>
					</Form.Item>
				</Form>

			</div>
		</div>


	);
};


export default RMA;