import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from "base/store";
import { Button, Modal, Spin } from "antd";
import savePDF from "lib/saveb64PDF";



const OrderPDFModal = () => {
	const dispatch = useDispatch();

	const loading = useSelector(state => state.orders.invoiceModalLoading),
		  visible = useSelector(state => state.orders.showInvoiceModal),
		  docNum  = useSelector(state => state.orders.invoiceModalDocNum),
		  pdf     = useSelector(state => state.orders.orderPDFs[ state.orders.invoiceModalDocEntry ]);


	const resetModal = useCallback(() => {
		dispatch(actions.orders.resetInvoiceModal());
	}, [ dispatch ]);


	return (
		<Modal
			title={`Invoice ${docNum}`}
			className="ts-pdf-modal"
			visible={visible}
			centered
			closable={false}
			footer={[
				pdf ?
				<Button
					key="pdfnewtab"
					onClick={() => {
						savePDF(pdf, `GES Invoice ${docNum}`)
					}}
					type="primary"
				>Download PDF</Button>
					: null,
				<Button key="close" onClick={resetModal} disabled={loading}>
					Close
				</Button>,
			]}
		>

			{pdf ? <embed src={`data:application/pdf;base64,${pdf}`} className="ts-pdf" /> : <Spin />}

		</Modal>

	)
};


export default OrderPDFModal;