import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from "base/store";
import { Button, Col, Divider, Row, Tooltip, Typography } from "antd";
import { EditOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import AddressModal from './AddressModal';



const Addresses = () => {
	const dispatch = useDispatch();

	const shippingAddresses = useSelector(state => state.addresses.shippingAddresses),
		  billingAddresses  = useSelector(state => state.addresses.billingAddresses),
		  loading           = useSelector(state => state.addresses.loading);


	const refreshAddresses = useCallback(() => {
		dispatch(actions.addresses.refreshAddresses());
	}, [ dispatch ]);

	const resetAddresses = useCallback(() => {
		dispatch(actions.addresses.resetAddresses());
	}, [ dispatch ]);

	const handleAddNew = useCallback((type) => () => {
		dispatch(actions.addresses.resetModal({ show_modal: true, address_type: type }));
	}, [ dispatch ]);

	const handleEdit = useCallback((type, id) => () => {
		const addresses = type === 'B' ? billingAddresses : shippingAddresses;
		dispatch(actions.addresses.resetModal({ show_modal: true, is_new: false, ...addresses[ id ] }));
	}, [ dispatch, billingAddresses, shippingAddresses ]);




	useEffect(() => {
		refreshAddresses();

		return () => resetAddresses();

	}, [ refreshAddresses, resetAddresses ]);


	return (
		<div>
			<Divider>
				Shipping Addresses <Button onClick={handleAddNew('S')} icon={<PlusOutlined />}> Add New</Button> <Button
				onClick={refreshAddresses}
				shape="circle"
				icon={<ReloadOutlined />}
				loading={loading}
			/>
			</Divider>
			<Row className="ts-home-content-container">
				{shippingAddresses.map((address, index) => (
					<Col key={address.address_id + address.address_type} xs={24} md={12} xl={6}>
						<div className="ts-address-card">
							<Typography.Title level={4}>{address.address_id} <Tooltip title="Edit"><Button
								shape="circle"
								icon={<EditOutlined />}
								onClick={handleEdit('S', index)}
							/></Tooltip>
							</Typography.Title>
							<Typography>{address.address_street}</Typography>
							<Typography>{address.address_block}</Typography>
							<Typography>{address.address_city}, {address.address_state} {address.address_zip}</Typography>
							<Typography>{address.address_country}</Typography>
						</div>
					</Col>
				))}
			</Row>

			<Divider>
				Billing Addresses <Button onClick={handleAddNew('B')} icon={<PlusOutlined />}> Add New</Button> <Button
				onClick={refreshAddresses}
				shape="circle"
				icon={<ReloadOutlined />}
				loading={loading}
			/>
			</Divider>
			<Row className="ts-home-content-container">
				{billingAddresses.map((address, index) => (
					<Col key={address.address_id + address.address_type} xs={24} md={12} xl={6}>
						<div className="ts-address-card">
							<Typography.Title level={4}>{address.address_id} <Tooltip title="Edit"><Button
								shape="circle"
								icon={<EditOutlined />}
								onClick={handleEdit('B', index)}
							/></Tooltip>
							</Typography.Title>
							<Typography>{address.address_street}</Typography>
							<Typography>{address.address_block}</Typography>
							<Typography>{address.address_city}, {address.address_state} {address.address_zip}</Typography>
							<Typography>{address.address_country}</Typography>
						</div>
					</Col>
				))}
			</Row>

			<AddressModal />
		</div>

	)
};


export default Addresses;