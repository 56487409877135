import React from 'react';
import { Layout as AntLayout, Menu } from 'antd';
import gesLogo from 'images/logo.svg'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';



const { Header, Footer, Content } = AntLayout;
const year = new Date().getFullYear();



const Layout = (props) => {
	const loggedIn = useSelector(state => state.auth.loggedIn);

	return (
		<div>
			<AntLayout>
				<Header className="header">
					<div className="ts-header-container">
						<Link className="header-logo" to="/">
							<img src={gesLogo} alt="Global Electronic Services: Repair, Consulting, Sales" />
						</Link>
					</div>
				</Header>
				<Header className="nav">

					{loggedIn === true &&
					<Menu
						mode="horizontal"
						className="nav-menu"
						selectedKeys={[ 'account' ]}
					>
						<Menu.Item key="home"><Link to="/">Home</Link></Menu.Item>
						<Menu.SubMenu
							title="Invoices"
							popupOffset={[ 0, 2 ]}
							key="invoices"
						>
							<Menu.Item key="invoices_paid"><Link to="/invoices/paid">Paid</Link></Menu.Item>
							<Menu.Item key="invoices_unpaid"><Link to="/invoices/unpaid">Unpaid</Link></Menu.Item>
							<Menu.Item key="invoices_view_all"><Link to="/invoices/all">View All</Link></Menu.Item>
						</Menu.SubMenu>

						<Menu.SubMenu
							title="Orders"
							popupOffset={[ 0, 2 ]}
							key="orders"
						>
							<Menu.Item key="orders_awaiting_approval"><Link to="/orders/awaiting_approval">Awaiting
								Approval</Link></Menu.Item>
							<Menu.Item key="orders_repaired_and_tested"><Link to="/orders/repaired">Repaired and
								Tested</Link></Menu.Item>
							<Menu.Item key="orders_in_process"><Link to="/orders/processing">In
								Process</Link></Menu.Item>
							<Menu.Item key="orders_view_all"><Link to="/orders/all">View All</Link></Menu.Item>
						</Menu.SubMenu>

						<Menu.SubMenu
							title="Request RMA"
							popupOffset={[ 0, 2 ]}
							key="rma"
						>
							<Menu.Item key="rma_new"><Link to="/rma">New RMA Number</Link></Menu.Item>
						</Menu.SubMenu>

						<Menu.SubMenu
							title="My Account"
							popupOffset={[ 0, 2 ]}
							key="account"
						>
							<Menu.Item key="account_paid"><Link to="/contact">Contact Global</Link></Menu.Item>
							<Menu.Item key="account_address_manager">
								<Link to="/account/addresses">Address Manager</Link>
							</Menu.Item>
							<Menu.Item key="account_log_out"><Link to="/logout">Log Out</Link></Menu.Item>
						</Menu.SubMenu>
					</Menu>
					}
				</Header>

				<Content className="ts-content">
					{props.children}
				</Content>

				<Footer className="ts-footer" align="center">
					&copy; {year} Global Electronic Services, Inc. All rights reserved.
				</Footer>
			</AntLayout>

		</div>
	);
};



export default Layout;