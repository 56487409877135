import b64toBlob from "b64-to-blob";
import FileSaver from 'file-saver';


const savePDF = (b64string, filename) => {
	let blob = b64toBlob(b64string, "application/pdf");
	return FileSaver.saveAs(blob, `${filename}.pdf`);
};


export default savePDF;