import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'base/store';
import Login from 'pages/Login';



const WrappedRoute = ({ extras, render, ...route }) => {

	const dispatch       = useDispatch(),
		  setLayoutState = (data) => dispatch(actions.layout.setState(data));

	const loggedIn = useSelector(state => state.auth.loggedIn);
	const location = useLocation().pathname;


	useEffect(() => {
		let update = {
			useLayout: extras.useLayout,
		};

		if(extras.title !== null) {
			update.title = extras.title;
		}

		setLayoutState(update);
	});

	if(!loggedIn && location !== '/logout') {
		return <Login />
	}

	return (
		<Route {...route}>{render}</Route>
	)

};




export default WrappedRoute;