import React, { useCallback } from 'react';
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { actions } from "../../base/store";



const OrderApproveButton = ({ docEntry, docNum, gesNum, rowID }) => {
	const dispatch = useDispatch();

	const openApprovalModal = useCallback(() => {
		dispatch(actions.orders.setState({
			showApprovalModal: true,
			approvalModalDocNum: docNum,
			approvalModalDocEntry: docEntry,
			approvalModalGESNumber: gesNum,
			approvalModalRowID: rowID,
		}));
	}, [ dispatch, docEntry, docNum, gesNum, rowID ]);


	return (
		<Button
			type="primary"
			size="small"
			onClick={openApprovalModal}
		>
			Approve
		</Button>
	)
};



export default OrderApproveButton;