import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from "base/store";
import { Button, Form, Input, Modal, Select } from "antd";



const AddressModal = () => {
	const dispatch = useDispatch();

	const pending = useSelector(state => state.addresses.pending);

	const refreshAddresses = useCallback(() => {
		dispatch(actions.addresses.refreshAddresses());
	}, [ dispatch ]);

	const resetModal = useCallback(() => {
		dispatch(actions.addresses.resetModal({ show_modal: false }));
	}, [ dispatch ]);

	const saveAddress = useCallback((data = {}) => {
		dispatch(actions.addresses.saveAddress(data));
	}, [ dispatch ]);

	const isNew   = useSelector(state => state.addresses.is_new),
		  visible = useSelector(state => state.addresses.show_modal);


	const fields = [
		{ name: 'address_id', value: useSelector(state => state.addresses.address_id) },
		{ name: 'address_type', value: useSelector(state => state.addresses.address_type) },
		{ name: 'address_name', value: useSelector(state => state.addresses.address_name) },
		{ name: 'address_street', value: useSelector(state => state.addresses.address_street) },
		{ name: 'address_block', value: useSelector(state => state.addresses.address_block) },
		{ name: 'address_city', value: useSelector(state => state.addresses.address_city) },
		{ name: 'address_state', value: useSelector(state => state.addresses.address_state) },
		{ name: 'address_zip', value: useSelector(state => state.addresses.address_zip) },
	];

	const [ form ] = Form.useForm();
	const handleSubmit = (values) => {
		saveAddress(values);
	};

	useEffect(() => {
		if(!visible) {
			form.resetFields();
		}
	}, [ visible, form ]);


	return (
		<Modal
			title={isNew ? 'New Address' : 'Edit Address'}
			visible={visible}
			onOk={refreshAddresses}
			onCancel={resetModal}
			forceRender
			centered
			footer={[
				<Button key="cancel" onClick={resetModal} disabled={pending}>
					Cancel
				</Button>,
				<Button key="submit" type="primary" onClick={form.submit} loading={pending}>
					Save
				</Button>,
			]}
		>
			<Form
				name="address_edit"
				layout="horizontal"
				labelCol={{xs: { span: 24 }, lg: {span: 8}}}
				wrapperCol={{xs: { span: 24 }, lg: {span: 16}}}
				labelAlign="left"
				fields={fields}
				form={form}
				onFinish={handleSubmit}
			>
				<Form.Item
					name="address_type"
					label="Address Type"
					rules={[ { required: true, message: 'Address Type is required' } ]}
				>
					<Select>
						<Select.Option value="S">Shipping</Select.Option>
						<Select.Option value="B">Billing</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item
					name="address_id"
					label="Address Name"
					rules={[
						{ required: true, message: 'Address Name is required' },
						{
							max: 50,
							message: 'Address Name is limited to 50 characters',
						},
					]}
				>
					<Input disabled={!isNew} maxLength={50} />
				</Form.Item>

				<Form.Item
					name="address_street"
					label="Address Line 1"
					rules={[
						{ required: true, message: 'Address Line 1 is required' },
						{
							max: 100,
							message: 'Address Line 1 is limited to 100 characters',
						},
					]}
				>
					<Input maxLength={100} />
				</Form.Item>

				<Form.Item
					name="address_block"
					label="Address Line 2"
					rules={[
						{
							max: 100,
							message: 'Address Line 2 is limited to 100 characters',
						},
					]}
				>
					<Input maxLength={100} />
				</Form.Item>

				<Form.Item
					name="address_city"
					label="City"
					rules={[
						{ required: true, message: 'City is required' },
						{
							max: 100,
							message: 'City is limited to 100 characters',
						},
					]}
				>
					<Input maxLength={100} />
				</Form.Item>

				<Form.Item
					name="address_state"
					label="State"
					rules={[ { required: true, message: 'State is required' } ]}
				>
					<Select>
						<Select.Option value="AL">Alabama</Select.Option>
						<Select.Option value="AK">Alaska</Select.Option>
						<Select.Option value="AZ">Arizona</Select.Option>
						<Select.Option value="AR">Arkansas</Select.Option>
						<Select.Option value="CA">California</Select.Option>
						<Select.Option value="CO">Colorado</Select.Option>
						<Select.Option value="CT">Connecticut</Select.Option>
						<Select.Option value="DE">Delaware</Select.Option>
						<Select.Option value="DC">District of Columbia</Select.Option>
						<Select.Option value="FL">Florida</Select.Option>
						<Select.Option value="GA">Georgia</Select.Option>
						<Select.Option value="HI">Hawaii</Select.Option>
						<Select.Option value="ID">Idaho</Select.Option>
						<Select.Option value="IL">Illinois</Select.Option>
						<Select.Option value="IN">Indiana</Select.Option>
						<Select.Option value="IA">Iowa</Select.Option>
						<Select.Option value="KS">Kansas</Select.Option>
						<Select.Option value="KY">Kentucky</Select.Option>
						<Select.Option value="LA">Louisiana</Select.Option>
						<Select.Option value="ME">Maine</Select.Option>
						<Select.Option value="MD">Maryland</Select.Option>
						<Select.Option value="MA">Massachusetts</Select.Option>
						<Select.Option value="MI">Michigan</Select.Option>
						<Select.Option value="MN">Minnesota</Select.Option>
						<Select.Option value="MS">Mississippi</Select.Option>
						<Select.Option value="MO">Missouri</Select.Option>
						<Select.Option value="MT">Montana</Select.Option>
						<Select.Option value="NE">Nebraska</Select.Option>
						<Select.Option value="NV">Nevada</Select.Option>
						<Select.Option value="NH">New Hampshire</Select.Option>
						<Select.Option value="NJ">New Jersey</Select.Option>
						<Select.Option value="NM">New Mexico</Select.Option>
						<Select.Option value="NY">New York</Select.Option>
						<Select.Option value="NC">North Carolina</Select.Option>
						<Select.Option value="ND">North Dakota</Select.Option>
						<Select.Option value="OH">Ohio</Select.Option>
						<Select.Option value="OK">Oklahoma</Select.Option>
						<Select.Option value="OR">Oregon</Select.Option>
						<Select.Option value="PA">Pennsylvania</Select.Option>
						<Select.Option value="RI">Rhode Island</Select.Option>
						<Select.Option value="SC">South Carolina</Select.Option>
						<Select.Option value="SD">South Dakota</Select.Option>
						<Select.Option value="TN">Tennessee</Select.Option>
						<Select.Option value="TX">Texas</Select.Option>
						<Select.Option value="UT">Utah</Select.Option>
						<Select.Option value="VT">Vermont</Select.Option>
						<Select.Option value="VA">Virginia</Select.Option>
						<Select.Option value="WA">Washington</Select.Option>
						<Select.Option value="WV">West Virginia</Select.Option>
						<Select.Option value="WI">Wisconsin</Select.Option>
						<Select.Option value="WY">Wyoming</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item
					name="address_zip"
					label="ZIP Code"
					rules={[
						{ required: true, message: 'ZIP Code is required' },
						{
							max: 20,
							message: 'ZIP Code is limited to 20 characters',
						},
					]}
				>
					<Input maxLength={20} />
				</Form.Item>
			</Form>
		</Modal>

	)
};


export default AddressModal;