import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from "base/store";
import { Modal } from "antd";
import InvoicePaymentCCForm from './InvoicePaymentCCForm';
import InvoiceExternalError from "./InvoiceExternalError";
import InvoiceAmountsForm from "./InvoiceAmountsForm";
import InvoicePaymentStatus from "./InvoicePaymentStatus";



const InvoicePaymentModal = () => {
	const dispatch = useDispatch();

	const visible           = useSelector(state => state.invoices.showPaymentModal),
		  externalError     = useSelector(state => state.invoices.externalError),
		  showAmountsForm   = useSelector(state => (!externalError && state.invoices.showAmountsForm)),
		  showPaymentForm   = useSelector(state => (!externalError && state.invoices.showPaymentForm)),
		  showPaymentStatus = useSelector(state => (!externalError && state.invoices.showPaymentStatus)),
		  capturing         = useSelector(state => (!externalError && state.invoices.capturing));


	const handleClose = useCallback(() => {
		dispatch(actions.invoices.closePaymentModal());
	}, [ dispatch ]);



	return (
		<Modal
			title={`Invoice Payment`}
			visible={visible}
			maskClosable={false}
			centered
			onCancel={handleClose}
			footer={null}
			closable={!capturing}
		>
			{externalError && <InvoiceExternalError />}
			{showAmountsForm && <InvoiceAmountsForm />}
			{showPaymentForm && <InvoicePaymentCCForm />}
			{showPaymentStatus && <InvoicePaymentStatus />}

		</Modal>

	);
};


export default InvoicePaymentModal;