import React, { memo, useState } from 'react';
import { Button, Form, Input, InputNumber, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Cards from 'react-credit-cards';
import moment from 'moment';
import { actions } from './Invoices.redux';



const InvoicePaymentCCForm = memo(() => {
	const dispatch = useDispatch();

	const total                   = useSelector(state => state.invoices.paymentTotal),
		  numKeys                 = useSelector(state => state.invoices.selectedRows.length),
		  [ form ]                = Form.useForm(),
		  [ card, setCard ]       = useState({
			  card_cvv: '',
			  card_expiration: '',
			  reactcc_expiration: '',
			  cardholder_name: '',
			  card_number: '',
			  card_issuer: '',
			  isValid: false,
			  maxLength: 19,
		  }),
		  [ focused, setFocused ] = useState('');


	const fields = [
		{ name: 'cardholder_name', value: card.cardholder_name },
		{ name: 'card_number', value: card.card_number },
		{ name: 'card_expiration', value: card.card_expiration },
		{ name: 'card_cvv', value: card.card_cvv },
	];


	const handleSubmit = (values) => {
		if(!card.isValid) {
			return false;
		}

		dispatch(actions.capturePayment({
			total: total,
			cardholder_name: card.cardholder_name,
			card_number: card.card_number,
			card_expiration: card.reactcc_expiration,
			card_cvv: card.card_cvv,
			card_issuer: card.card_issuer,
		}));
	};


	const handleFieldChange = (changedFields, allFields) => {
		if(!changedFields.length) {
			return false;
		}

		let field  = changedFields[ 0 ],
			name   = field.name[ 0 ],
			value  = field.value === null ? '' : field.value,
			update = { ...card, [ name ]: value };

		if(name === 'card_expiration_month' || name === 'card_expiration_year') {
			if(!update.card_expiration_month || !update.card_expiration_year) {
				update.reactcc_expiration = '';
			} else {
				let month = String(update.card_expiration_month).padStart(2, '0');
				let year = String(update.card_expiration_year).padStart(4, '0');
				update.reactcc_expiration = moment(`${year}-${month}`, 'YYYY-MM').format('MMYYYY');
			}

		}

		if(name === 'card_number') {
			update[ name ] = value.replace(/\D/g, '');
		}

		setCard(update);
	};


	const handleInputFocus = name => e => {
		setFocused(name);
	};


	const handleCardCallback = (type, isValid) => {
		if(card.card_issuer !== type.issuer || card.isValid !== isValid || card.maxLength !== type.maxLength) {
			setCard({
				...card,
				card_issuer: type.issuer,
				isValid: isValid,
				maxLength: type.maxLength,
			});
		}
	};


	return (
		<div>
			<div className="payment_form_item">
				<Typography.Text>
					You are paying {numKeys} {numKeys === 1 ? 'invoice' : 'invoices'} for a total of
					${total.toFixed(2)}. Please enter your payment details below and click the Submit button.
				</Typography.Text>
			</div>

			<Cards
				cvc={card.card_cvv}
				expiry={card.reactcc_expiration}
				focused={focused}
				name={card.cardholder_name}
				number={card.card_number}
				callback={handleCardCallback}
				acceptedCards={[ 'visa', 'mastercard', 'discover', 'amex' ]}
			/>

			<Form
				name="payment_form"
				layout="vertical"
				labelCol={{ xs: { span: 24 } }}
				wrapperCol={{ xs: { span: 24 } }}
				labelAlign="left"
				form={form}
				fields={fields}
				onFieldsChange={handleFieldChange}
				onFinish={handleSubmit}
				className="ts-form-margin"
			>
				<Form.Item
					name="cardholder_name"
					label="Cardholder Name"
					rules={[
						{ required: true, message: 'Cardholder Name is required' },
					]}
				>
					<Input maxLength={200} onFocus={handleInputFocus('name')} />
				</Form.Item>


				<Form.Item
					name="card_number"
					label="Card Number"
					rules={[
						{ required: true, message: 'Card Number is required' },
					]}
				>
					<Input
						maxLength={card.maxLength}
						onFocus={handleInputFocus('number')}
						className="ts-full-width"
					/>
				</Form.Item>


				<Form.Item label="Expiration Date (MM/YYYY)">
					<Input.Group>
						<Form.Item
							name="card_expiration_month"
							noStyle
							rules={[
								{ required: true, message: 'Expiration Month is required' },
							]}
						>
							<InputNumber
								onFocus={handleInputFocus('expiry')}
								maxLength={2}
								min={1}
								max={12}
								placeholder={moment().format('MM')}
							/>
						</Form.Item>

						{' / '}

						<Form.Item
							name="card_expiration_year"
							noStyle
							rules={[
								{ required: true, message: 'Expiration Year is required' },
							]}
						>
							<InputNumber
								onFocus={handleInputFocus('expiry')}
								maxLength={4}
								min={Number(moment().format('YYYY'))}
								max={moment().add(20, 'years').format('YYYY')}
								placeholder={moment().format('YYYY')}
							/>
						</Form.Item>
					</Input.Group>
				</Form.Item>



				<Form.Item
					name="card_cvv"
					label="CVV/CVC Code"
					rules={[
						{ required: true, message: 'CVV/CVC Code is required' },
					]}
				>
					<InputNumber maxLength={5} onFocus={handleInputFocus('cvc')} />
				</Form.Item>


				<Button htmlType="submit" disabled={!card.isValid}>
					Submit
				</Button>
			</Form>
		</div>
	);
});


export default InvoicePaymentCCForm;