import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '../store'
import { routes } from '../routes';
import WrappedRoute from './WrappedRoute';
import Layout from '../Layout';
import NotFound from '../../pages/NotFound';



const Router = (props) => (
	<ConnectedRouter history={history}>
		<Route
			render={({ location }) => (
				<Layout>
					<Switch
						location={location}
						key={location.key}
					>
						{routes.map(config => {
							return <WrappedRoute {...config.route} extras={config.extras}
												 render={config.render}
												 key={config.route.path}
							/>
						})}
						<Route component={NotFound} />
					</Switch>
				</Layout>
			)}
		/>
	</ConnectedRouter>
);




export default Router;