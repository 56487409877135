const makeSorter = (type = 'string', key) => (a, b) => {
	switch(type) {
		case 'string':
			return stringSorter(a[key], b[key]);
		case 'number':
			return numberSorter(a[key], b[key]);
		default:
			return stringSorter(a[key], b[key]);
	}
};



function stringSorter(a, b) {
	return ('' + a.toUpperCase()).localeCompare(b.toUpperCase());
}


function numberSorter(a, b) {
	return a - b;
}

export default makeSorter;