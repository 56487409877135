import axios from 'axios';
import DelayedPromise from "lib/DelayedPromise";



const defaultAddressState = {
	show_modal: false,
	is_new: true,
	address_id: '',
	address_name: '',
	address_street: '',
	address_block: '',
	address_city: '',
	address_state: '',
	address_zip: '',
	address_type: '',
};

const initialState = {
	pending: false,
	loading: false,
	shippingAddresses: [],
	billingAddresses: [],
	shippingIDs: [],
	billingIDs: [],
	showAddressForm: false,
	...defaultAddressState,
};


export const types = {
	SET_STATE: 'ADDRESSES/SET_STATE',
};




export default (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };
		default:
			return state;
	}
}



export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),


	resetModal: (extras = {}) => ({ type: types.SET_STATE, data: { ...defaultAddressState, ...extras } }),


	resetAddresses: () => ({ type: types.SET_STATE, data: { ...initialState } }),


	refreshAddresses: (silent = false) => async (dispatch) => {
		dispatch({ type: types.SET_STATE, data: { loading: true } });

		try {
			let addressRequest = await DelayedPromise(
				axios.get(process.env.REACT_APP_API_URL + '/account/addresses?silent=' + (silent ? 'true' : 'false')),
				1000,
			);
			let addresses = addressRequest.data.addresses;

			dispatch({
				type: types.SET_STATE, data: {
					loading: false,
					shippingAddresses: addresses.filter(addr => addr.address_type === 'S'),
					billingAddresses: addresses.filter(addr => addr.address_type === 'B'),
					shippingIDs: addresses
						.filter(addr => addr.address_type === 'S')
						.map(addr => addr.address_id.toUpperCase()),
					billingIDs: addresses
						.filter(addr => addr.address_type === 'B')
						.map(addr => addr.address_id.toUpperCase()),
				},
			});
		} catch(err) {
			dispatch({ type: types.SET_STATE, data: { loading: false } });
		}
	},


	saveAddress: (saveData) => async (dispatch, getState) => {

		dispatch({ type: types.SET_STATE, data: { pending: true } });

		try {
			await axios.post(process.env.REACT_APP_API_URL + '/account/addresses', saveData);

			dispatch(actions.resetModal({ pending: false }));
			dispatch(actions.refreshAddresses(true));
		} catch(err) {
			dispatch({ type: types.SET_STATE, data: { pending: false } });
		}

	},
};