function getResponseErrors(err) {
	let errors;

	try {
		errors = err.response.data.errors || [];
	} catch(error) {
		errors = [err.message];
	}

	return errors || [];
}


export default getResponseErrors;