import React from 'react';
import { Col, Menu, Row, Typography } from 'antd';
import { AuditOutlined, TagOutlined, ToolOutlined, UserOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom";



const Home = () => {
	return (

		<Row className="ts-home-content-container">
			<Col xs={24} md={12} xl={6}>
				<div className="ts-portal-card">
					<Typography.Title level={4}><AuditOutlined /> Invoices</Typography.Title>
					<Menu className="ts-home-menu">
						<Menu.Item key="invoices_paid"><Link to="/invoices/paid">Paid</Link></Menu.Item>
						<Menu.Item key="invoices_unpaid"><Link to="/invoices/unpaid">Unpaid</Link></Menu.Item>
						<Menu.Item key="invoices_view_all"><Link to="/invoices/all">View All</Link></Menu.Item>
					</Menu>
				</div>
			</Col>

			<Col xs={24} md={12} xl={6}>
				<div className="ts-portal-card">
					<Typography.Title level={4}><TagOutlined /> Orders</Typography.Title>
					<Menu className="ts-home-menu">
						<Menu.Item key="orders_awaiting_approval"><Link to="/orders/awaiting_approval">Awaiting Approval</Link></Menu.Item>
						<Menu.Item key="orders_repaired_and_tested"><Link to="/orders/repaired">Repaired and Tested</Link></Menu.Item>
						<Menu.Item key="orders_in_process"><Link to="/orders/processing">In Process</Link></Menu.Item>
						<Menu.Item key="orders_view_all"><Link to="/orders/all">View All</Link></Menu.Item>
					</Menu>
				</div>
			</Col>

			<Col xs={24} md={12} xl={6}>
				<div className="ts-portal-card">
					<Typography.Title level={4}><ToolOutlined /> Request RMA</Typography.Title>
					<Menu className="ts-home-menu">
						<Menu.Item key="rma_new"><Link to="/rma">New RMA Number</Link></Menu.Item>
					</Menu>
				</div>
			</Col>

			<Col xs={24} md={12} xl={6}>
				<div className="ts-portal-card">
					<Typography.Title level={4}><UserOutlined /> My Account</Typography.Title>
					<Menu className="ts-home-menu">
						<Menu.Item key="account_paid"><Link to="/contact">Contact Global</Link></Menu.Item>
						<Menu.Item key="account_address_manager"><Link to="/account/addresses">Address Manager</Link></Menu.Item>
						<Menu.Item key="account_log_out"><Link to="/logout">Log Out</Link></Menu.Item>
					</Menu>
				</div>
			</Col>
		</Row>

	)
};


export default Home;