import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { InputNumber } from 'antd';
import { actions } from 'base/store';




const InvoiceAmountInput = ({ invoiceKey }) => {
	const dispatch = useDispatch();
	const amount    = useSelector(state => state.invoices.paymentAmounts[ invoiceKey ].value);

	const handleAmountChange = value => {
		dispatch(actions.invoices.updatePaymentAmount(invoiceKey, value));
	};

	return (
		<InputNumber
			id={`invoice_amount_${invoiceKey}`}
			value={amount}
			onChange={handleAmountChange}
			precision={2}
			formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
		/>
	);
};



export default InvoiceAmountInput;