import React, { useCallback } from 'react';
import { Button, Modal, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'base/store';




const SignupModal = () => {
	const dispatch = useDispatch();

	const handleClose = useCallback((values) => {
		dispatch(actions.auth.setState({ showSignupModal: false }));
	}, [ dispatch ]);

	const visible = useSelector(state => state.auth.showSignupModal);



	return (
		<Modal
			title="Sign Up"
			visible={visible}
			onCancel={handleClose}
			footer={[
				<Button key="signup_close" onClick={handleClose} type="primary">
					OK
				</Button>,
			]}
		>
			<Typography.Text>
				Please contact your Global representative with the email address you'd like to use to access the
				Customer Portal. We will set up your account and send you a temporary password to log in with.
			</Typography.Text>

		</Modal>
	)
};

export default SignupModal;