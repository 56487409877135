import React, { memo } from 'react';
import { Result, Spin } from "antd";
import { useSelector } from "react-redux";



const InvoicePaymentStatus = memo(() => {

	const requestSent  = useSelector(state => state.invoices.paymentRequestSent),
		  captureError = useSelector(state => state.invoices.captureError),
		  capturing    = useSelector(state => state.invoices.capturing);


	return (
		<div>
			{capturing &&
			<Result
				icon={<Spin size="large" />}
				title="We're processing your payment..."
			/>


			}
			{(requestSent && !capturing && !captureError) &&
			<Result
				status="success"
				title="Payment Successful!"
				subTitle="All done! Please note that it may take a few minutes for the payment to show as applied to your invoice(s)."
			/>
			}


			{(requestSent && !capturing && captureError) &&
			<Result
				status="warning"
				title="Payment Error"
				subTitle="We were unable to process payment using the card details you entered. Please close this dialog and try again."
			/>}
		</div>
	);
});


export default InvoicePaymentStatus;