import makeSorter from "./makeSorter";


export default function makeAntColumns(columns) {
	return columns.map(colFixer)
}


function colFixer(column) {
	column = {...column};

	switch(column.sorter) {
		case 'string':
			column.sorter = makeSorter('string', column.sortIndex || column.dataIndex);
			break;
		case 'number':
			column.sorter = makeSorter('number', column.sortIndex || column.dataIndex);
			break;
		default:
			delete(column.sorter);
	}

	if(column.filters) {
		column.onFilter = (value, record) => record.order_status.includes(value);
	}

	if(!column.title) {
		column.title = column.dataIndex;
	}

	return column;
}
