import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import InvoiceAmountInput from "./InvoiceAmountInput";
import { Button, Typography } from "antd";
import { actions } from 'base/store';



const InvoiceAmountsForm = () => {
	const dispatch = useDispatch();
	const invoices = useSelector(state => state.invoices.selectedInvoices),
		  pending  = useSelector(state => state.invoices.validatingAmounts);

	function handleAmountValidation() {
		dispatch(actions.invoices.validateAmounts());
	}


	return (
		<div>
			<div className="payment_form_item">
				<Typography.Text>
					Please fill in the amount to pay for each invoice and then click Next. We've pre-filled the full
					invoice amounts, so if you would like to pay all selected invoices in full, you may use the Next
					button
					at the bottom of the form without making any changes.
				</Typography.Text>
			</div>

			{invoices.map(row => (
				<div key={row.invoice_key} className="payment_form_item">
					<strong>
						<label htmlFor={`invoice_amount_${row.invoice_key}`}>
							Invoice #{row.invoice_number} (${row.invoice_balance_string} Balance)
						</label>
					</strong>
					<div>
						<InvoiceAmountInput invoiceKey={row.invoice_key} />
					</div>

				</div>
			))}

			<Button
				type="primary"
				onClick={handleAmountValidation}
				loading={pending}
				disabled={pending}
			>
				Next
			</Button>
		</div>
	);
};



export default InvoiceAmountsForm;