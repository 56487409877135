import React, { useState } from 'react';
import { Button, Form, Input, message, Typography } from 'antd';
import { MailOutlined } from '@ant-design/icons'
import axios from 'axios';



const emailValidator = {
	validator: (rule, value) => {
		if(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
			return Promise.resolve();
		}

		return Promise.reject('Please enter a valid email address');
	},
	validateTrigger: 'onSubmit',
};



const Contact = () => {

	const [ submitted, setSubmit ]      = useState(false),
		  [ submitting, setSubmitting ] = useState(false);




	const handleSubmit = async (values) => {
		setSubmitting(true);

		try {
			await axios.post(`${process.env.REACT_APP_API_URL}/contact`, values);
			setSubmit(true);
		} catch(err) {
			message.error("There was an error sending your message. Please try again later.", 4);
			setSubmitting(false);
		}
	};


	if(submitted) {
		return (
			<div className="ts-rma-content-container">
				<div className="ts-portal-card">
					<Typography.Title level={4}><MailOutlined /> Contact Global</Typography.Title>
					<Typography.Text>
						Your message has been sent. You will receive a response soon.
					</Typography.Text>
				</div>
			</div>
		)
	}

	return (
		<div className="ts-rma-content-container">
			<div className="ts-portal-card">
				<Typography.Title level={4}><MailOutlined /> Contact Global</Typography.Title>

				<Form
					name="order_approval"
					layout="horizontal"
					labelCol={{ xs: { span: 24 }, lg: { span: 6 } }}
					wrapperCol={{ xs: { span: 24 }, lg: { span: 18 } }}
					labelAlign="left"
					onFinish={handleSubmit}
					className="ts-form-margin"
				>

					<Form.Item
						name="name"
						label="Your Name"
						rules={[ { required: true, message: 'Name address is required' } ]}
					>
						<Input maxLength={200} />
					</Form.Item>

					<Form.Item
						name="email"
						label="Your Email"
						rules={[ { required: true, message: 'Email address is required' }, () => (emailValidator) ]}
					>
						<Input maxLength={100} />
					</Form.Item>

					<Form.Item
						name="company"
						label="Your Company"
					>
						<Input
							maxLength={100}
						/>
					</Form.Item>

					<Form.Item
						name="phone"
						label="Your Phone Number"
					>
						<Input maxLength={50} />
					</Form.Item>

					<Form.Item
						name="message"
						label="Message"
						rules={[
							{ required: true, message: 'Message is required' },
						]}
					>
						<Input.TextArea
							autoSize={{ minRows: 8, maxRows: 8 }}
							maxLength={10000}
						/>
					</Form.Item>

					<Form.Item>
						<Button type="primary" htmlType="submit" loading={submitting}>
							Send Message
						</Button>
					</Form.Item>
				</Form>

			</div>
		</div>


	)
};


export default Contact;